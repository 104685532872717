//js
if (!global._babelPolyfill) {
  require("babel-polyfill");
}
require("./js/slider");
require("./js/scroll");
require("./js/vendor/jquery.scrollto");
require("./js/vendor/numscroller");
require("./js/helper");
require("./js/navigation");
require("./js/maps");
require("./js/main");

//fonts
require('./fonts/fonts.css');

// css
import "normalize.css";
require("./sass/style.scss");
