window.addEventListener("load", () => {
    
    const moveBlocks = document.querySelectorAll('.movement-block');

    if (moveBlocks.length) {
        window.addEventListener("scroll", (e) => {
            moveBlocks.forEach((item, index) => {
                const itemPageTop = item.getBoundingClientRect().top;
                const offset = item.clientHeight;

                if ((itemPageTop < offset) && (itemPageTop > 0)) {
                    const counter = offset/50;
                    const translate = itemPageTop/counter;

                    item.style.transform = `translateY(${Math.floor(translate)}%)`;
                }
            });
        });
    }

    const homeAnimation = document.getElementById('home-animation');

    if (homeAnimation) {

        const stones = homeAnimation.querySelector('.stones .inner');
        const textAbove = homeAnimation.querySelector('.text-above');
        //const foreground = homeAnimation.querySelector('.foreground');

        window.addEventListener("scroll", (e) => {
            const limiter = window.innerWidth > 1260 ? 2 : 2.5;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const translate = scrollTop/limiter;

            stones.style.transform = `translateY(${Math.floor(-(translate/4))}px)`;
            textAbove.style.transform = `translateY(${Math.floor(-(translate/3))}px)`;
            //foreground.style.transform = `translateY(${Math.floor(-(translate/3))}px)`;
        });
    }
});