// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

function pad(d) {
  return d < 10 ? "0" + d.toString() : d.toString();
}

/****** SLIDERS *******/
const sliders = {
  aboutUs: $(".about-us-slider-container .inner"),
  gallery: $(".slider-gallery-container .inner"),
  cooperation: $(".slider-cooperation-container > .inner"),
  csr: $(".csr-slider-container .inner")
};

sliders.aboutUs.on("beforeChange", function(
  event,
  slick,
  currentSlide,
  nextSlide
) {
  $(".about-us-slider-container")
    .find(".current")
    .html(pad(nextSlide + 1));
});

sliders.aboutUs.slick({
  dots: true,
  appendDots: $(".about-us-slider-dots-container"),
  infinite: true,
  slidesToShow: 1,
  autoplay: true,
  prevArrow: $(".about-us-slider-slider-prev"),
  nextArrow: $(".about-us-slider-slider-next"),
  speed: 500,
  fade: true,
  cssEase: "linear",
  pauseOnHover: false,
  autoplaySpeed: 5000
});

sliders.csr.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
  $(".csr-slider-container")
    .find(".current")
    .html(pad(nextSlide + 1));
});

sliders.csr.slick({
  dots: true,
  appendDots: $(".csr-slider-dots-container"),
  infinite: true,
  slidesToShow: 1,
  autoplay: true,
  prevArrow: $(".csr-slider-slider-prev"),
  nextArrow: $(".csr-slider-slider-next"),
  speed: 500,
  fade: true,
  cssEase: "linear",
  pauseOnHover: false,
  autoplaySpeed: 1500
});

sliders.gallery.on("beforeChange", function(
  event,
  slick,
  currentSlide,
  nextSlide
) {
  $(".slider-gallery-container")
    .find(".current")
    .html(pad(nextSlide + 1));
});

sliders.gallery.slick({
  dots: true,
  appendDots: $(".gallery-dots-container"),
  infinite: true,
  slidesToShow: 1,
  autoplay: true,
  prevArrow: $(".gallery-slider-prev"),
  nextArrow: $(".gallery-slider-next"),
  speed: 500,
  fade: true,
  cssEase: "linear",
  pauseOnHover: false,
  autoplaySpeed: 1500
});

sliders.cooperation.on("beforeChange", function(
  event,
  slick,
  currentSlide,
  nextSlide
) {
  $(".slider-cooperation-container")
    .find(".current")
    .html(pad(nextSlide + 1));
});

sliders.cooperation.slick({
  dots: true,
  appendDots: $(".cooperation-dots-container"),
  infinite: true,
  slidesToShow: 1,
  autoplay: true,
  prevArrow: $(".cooperation-slider-prev"),
  nextArrow: $(".cooperation-slider-next"),
  speed: 500,
  fade: true,
  cssEase: "linear",
  pauseOnHover: false,
  autoplaySpeed: 4000
});
