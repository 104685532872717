import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu, toggleLanguages } from "./navigation";
import FloatLabels from "./forms/float-labels";
import $ from "jquery";
import "./signal-animations";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();
  toggleLanguages();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }

  const toggleData = document.getElementById('toggle-administrator-data');
  
  if (toggleData) {
    toggleData.addEventListener('click', (e) => {
        e.preventDefault();
        $(toggleData).siblings('.content').slideToggle();
    });
  }

  const fileInput = document.getElementById('file');
  
  if (fileInput) {
    fileInput.addEventListener('change', function() {
        const fileName = this.files[0].name;
        $(this).closest('.file-field').find('.file-placeholder').html(fileName);
    });
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
