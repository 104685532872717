//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";

const smoothScroll = new SmoothScroll('a[href*="#"]', {
  ignore: "[data-vc-container], .toggle-data, .languages a",
  topOnEmptyHash: false
});

window.addEventListener("load", () => {

  const activeTile = document.querySelector('.children-pages-container .child-tile.active');
  
  if (activeTile) {
    smoothScroll.animateScroll(activeTile, undefined, { offset: -150 });
  }

  const goTop = document.querySelector('.go-top');
  
  if (goTop) {
  	goTop.addEventListener('click', () => smoothScroll.animateScroll(0));
  }
});
